import { Controller } from "stimulus"
import * as clipboard from "clipboard-polyfill/text"

export default class extends Controller {
  static targets = [ "source" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy(e) {
    e.preventDefault();
    clipboard.writeText(this.sourceTarget.value);
    e.currentTarget.setAttribute('data-tooltip', 'Copié');
  }
}
