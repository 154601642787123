import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'checkoutOption', 'productsJson' ];

  connect() {
    this.cart = JSON.parse(localStorage.getItem('cart'));
    if (this.cart === null) {
      this.cart = [];
    }
    this.check_cart_validity();
    this.init_products_view();
    this.update_car_display_view();
    this.update_checkout_options();
    this.update_product_json();
  }

  update_car_display_view() {
    const section = document.querySelector('.cart-details-container');
    if (!(section === null)) {
      section.innerHTML = this._build_cart_view();
    }
  }

  init_products_view() {
    const products = Array.from(document.querySelectorAll('.product-element'));
    products.forEach((elt) => {
      const { id } = elt.dataset;
      this._init_product(id);
    });
  }

  update_checkout_options() {
    const has_product = this.cart.length > 0;
    this.checkoutOptionTargets.forEach(elt => {
      if (has_product) {
        elt.classList.remove('is-hidden');
      }
      else {
        elt.classList.add('is-hidden');
      }
    });
  }

  update_product_json() {
    if (this.hasProductsJsonTarget) {

      let products = this.cart.map(p =>  {
         return {'id': p['id'], 'quantity': p['quantity']};
      });

      this.productsJsonTarget.value = JSON.stringify(products);
    }

    const section = document.querySelector('.cart-from-container');
    if (!(section === null) && this.cart.length == 0) {
      section.innerHTML = '';
    }
  }

  check_cart_validity() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const feedback = urlParams.get('feedback');
    if (feedback == 'success') {
      this.cart = [];
      this._update_cart_in_storage();
    }
  }

  add(e) {
    const { id, name, price, image } = e.currentTarget.dataset;

    this._update_quantity_in_ui(id, 1);
    this._display_quantity_details(id);
    this._hide_add_cart_button(id);

    this.cart.push({
      'id': id,
      'name': name,
      'price': price,
      'image': image,
      'quantity': 1
    });

    this._update_cart_in_storage();
    this.update_checkout_options();
  }

  remove(e) {
    const { id } = e.currentTarget.dataset;
    this._update_quantity_in_ui(id, 0);

    this._hide_quantity_details(id);
    this._display_add_cart_button(id);

    this._remove_product(id);
    this.update_checkout_options();
    this.update_product_json();
  }

  decrement(e) {
    const { id } = e.currentTarget.dataset;
    const product = this._find_product(id);
    if (product['quantity'] == 1) {
      this._update_quantity_in_ui(id, 0);

      this._hide_quantity_details(id);
      this._display_add_cart_button(id);

      this._remove_product(id);
      this.update_checkout_options();
    } else {
      this._update_product_quantity(id, product['quantity']-1);
      this._update_quantity_in_ui(id, product['quantity']-1);
    }
  }

  increment(e) {
    const { id } = e.currentTarget.dataset;
    const product = this._find_product(id);
    this._update_product_quantity(id, product['quantity']+1);
    this._update_quantity_in_ui(id, product['quantity']+1);
  }

  _has_product(id) {
    const product = this.cart.find(element => element['id'] == id);
    if (product) {
      return true;
    } else {
      return false;
    }
  }

  _find_product(id) {
    return this.cart.find(element => element['id'] == id);
  }

  _update_product_quantity(id, quantity) {
    let product = this._find_product(id);
    product = {
      'id': product['id'],
      'name': product['name'],
      'price': parseInt(product['price']),
      'image': product['image'],
      'quantity': quantity
    }
    const index = this.cart.findIndex(element => element['id'] == id);
    this.cart.splice(index, 1);
    this.cart.push(product);
    this._update_cart_in_storage();
  }

  _remove_product(id) {
    const index = this.cart.findIndex(element => element['id'] == id);
    this.cart.splice(index, 1);
    this._update_cart_in_storage();
  }

  _update_cart_in_storage() {
    localStorage.setItem('cart', JSON.stringify(this.cart));
    this.update_car_display_view();
  }

  _display_quantity_details(id) {
    const elt = this._get_product_quantity_element(id);
    if (elt) {
      elt.classList.remove('is-hidden');
    }
  }

  _hide_quantity_details(id) {
    const elt = this._get_product_quantity_element(id);
    if (elt) {
      elt.classList.add('is-hidden');
    }
  }

  _display_add_cart_button(id) {
    const elt = this._get_product_add_to_cart_element(id);
    if (elt) {
      elt.classList.remove('is-hidden');
    }
  }

  _hide_add_cart_button(id) {
    const elt = this._get_product_add_to_cart_element(id);
    if (elt) {
      elt.classList.add('is-hidden');
    }
  }

  _get_product_element(id) {
    return document.querySelector(`#product-${id}`);
  }

  _get_product_add_to_cart_element(id) {
    return document.querySelector(`#product-${id} .add-button`);
  }

  _get_product_quantity_element(id) {
    return document.querySelector(`#product-${id} .quantity-details`);
  }

  _update_quantity_in_ui(id, quantity) {
    const elt = document.querySelector(`#product-${id} .product-quantity .product-count`);
    if (elt) {
      elt.innerText = quantity;
    }
  }

  _init_product(id) {
    if (this._has_product(id)) {
      const product = this._find_product(id);
      this._update_quantity_in_ui(id, product['quantity']);
      this._display_quantity_details(id);
      this._hide_add_cart_button(id);
    } else {
      this._update_quantity_in_ui(id, 0);

      this._hide_quantity_details(id);
      this._display_add_cart_button(id);
    }
  }

  _build_cart_view() {
    let content = '<div class="table-container">';

    if (this.cart.length == 0) {
      content += '<p>Votre panier est vide.</p>'
      content += '</div>'
      return content;
    }

    content += '<table class="table is-light is-fullwidth">';
    content += "<tr><th class='cart-product-image'></th><th>Q</th><th>P.U (F)</th><th>P.T (F)</th><th></th></tr>";
    let total = 0;
    for (let i = 0; i < this.cart.length; i++) {
      const product = this.cart[i];
      content += '<tr>'
      content += `<td class='cart-product-image'><img style="max-width: 90px;" src='${product['image']}'</td>`
      content += `<td class='cart-product-name'>${product['name']} (x${product['quantity']})</td>`
      content += `<td class='cart-product-pu'>${product['price']}</td>`
      content += `<td class='cart-product-pt'>${product['quantity']*product['price']}</td>`
      content += `<td class='cart-actions'><button class='button is-white has-text-danger' type="button" name="button" data-id="${product['id']}" data-action="click->cart#remove"><span class="icon"><i class="fas fa-trash"></i></span></button></td>`
      content += '</tr>'
      total += product['quantity']*product['price'];
    }

    let transaction_fees = parseInt(total*2/100, 10);
    let total_with_fees = total + transaction_fees;

    content += `<tr><td colspan='5' style="text-align: left;"><strong>Total Produits: <span class='cart-total'>${total} F CFA</span></strong></td></tr>`
    content += `<tr><td colspan='5' style="text-align: left;"><strong>Frais de transaction: <span class='cart-total'>${transaction_fees} F CFA</span></strong></td></tr>`
    content += `<tr><td colspan='5' style="text-align: left;"><strong>Total: <span class='cart-total'>${total_with_fees} F CFA</span></strong></td></tr>`
    content += '</table>'
    content += '</div>'
    return content;
  }
}
