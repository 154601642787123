import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ];

  show(e) {
    e.preventDefault();
    this.contentTarget.classList.remove('is-hidden');
  }
}
