import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ];

  toggleMenu(e) {
    e.preventDefault();
    this.contentTarget.classList.toggle('menu-toggle');
  }
}
