import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['mensualGrowth', 'content', 'contentDistributor', 'contentDistributorOrder', 
                    'contentBenefictInPercentage', 'initialDistributorCount','orderDistributorCount', 
                    'orderAverageByDistributor', 'actifDistributorPercentage', 'benefictInPercentage',
                    'expense', 'contentBonusInPercentage', 'bonusInPercentage', 'finalDistributor',
                    'netFinancial', 'totalExpenses', 'totalBonus', 'totalPrime', 'totalBenefict',
                    'contentPrimeInPercentage', 'primeInPercentage'
                    ];

  initialize(){
    this.options = {
      legend: {
        display: true,
        labels: {
            align: 'start',
            fontColor: '#333333',
            padding: 20,
            position: 'left'
        }
      },
      scales: {
       yAxes: [{
        gridLines: {
         color: "rgba(0, 0, 0, .05)"
        }
       }]
      }
    }
  }

  connect() {
    this.mensualGrowthTarget.value = 5;
    this.months = [1,2,3,4,5,6,7,8,9,10,11,12];
    this.distributorsGrowth = [];
    this.salesGrowth = [];
    this.benefict = [];
    this.bonus = [];
    this.prime = [];
    this._simulateDistributor();
    this._simulateDistributorOrder();
    this._benefictInPercentage();
    this._bonusInPercentage();
    this._primeInPercentage();
    this._getFinaleDistributor();
    this._getNetFinancial();
    this._getTotalExpenses();
    this._getTotalBonus();
    this._getTotalPrime();
    this._getTotalBenefict();
  }

  simulate(e){
    this._simulateDistributor();
    this._simulateDistributorOrder();
    this._benefictInPercentage();
    this._bonusInPercentage();
    this._primeInPercentage();
    this._getFinaleDistributor();
    this._getNetFinancial();
    this._getTotalExpenses();
    this._getTotalBonus();
    this._getTotalPrime();
    this._getTotalBenefict();
  }



  _simulateDistributor() {
    this.distributorsGrowth = [parseInt(this.initialDistributorCountTarget.value)];

    for (var i = 1; i < 12; i++ ) {
      this.distributorsGrowth.push(this._calculateNextValueBasedOnGrowth(this.distributorsGrowth[i-1]));
    }
    new Chart(this.contentDistributorTarget, {
      type: 'line',
      data: {
          labels: this.months,
          datasets: [
            {
              label: '# Distributors simulation',
              data: this.distributorsGrowth,
              borderWidth: 1
            }
          ]
      },
      options: this.options
    });
  }

  _calculateNextValueBasedOnGrowth(value) {
    return parseInt(value) + parseInt(value*this.mensualGrowthTarget.value/100);
  }

  _simulateDistributorOrder(){
    this.salesGrowth = [this._calculateMonthSales(0)];

    for (var i = 1; i < 12; i++ ) {
      this.salesGrowth.push(this._calculateMonthSales(i));
    }

    new Chart(this.contentDistributorOrderTarget, {
      type: 'line',
      data: {
          labels: this.months,
          datasets: [
            {
              label: '# Achat moyen par distributeur actif',
              data: this.salesGrowth,
              borderWidth: 1
            }
          ]
      },
      options: this.options
    });
  }

  _calculateMonthSales(i) {
    return parseInt(this.orderAverageByDistributorTarget.value*(this.actifDistributorPercentageTarget.value/100)*this.distributorsGrowth[i])
  }

  _benefictInPercentage(){
    this.benefict = [this._calculateBenefict(0)];

    for (var i = 1; i < 12; i++ ) {
      this.benefict.push(this._calculateBenefict(i));
    }

    new Chart(this.contentBenefictInPercentageTarget, {
      type: 'line',
      data: {
          labels: this.months,
          datasets: [
            {
              label: '# Bénéfice brute en pourcentage',
              data: this.benefict,
              borderWidth: 1
            }
          ]
      },
      options: this.options
    });
  }

  _calculateBenefict(i) {
    return parseInt( (this.benefictInPercentageTarget.value*this._calculateMonthSales(i)/100) - this.expenseTarget.value - this.bonusInPercentageTarget.value)
  }

  _bonusInPercentage(){
    this.bonus = [this._calculateBonus(0)];

    for (var i = 1; i < 12; i++ ) {
      this.bonus.push(this._calculateBonus(i));
    }

    new Chart(this.contentBonusInPercentageTarget, {
      type: 'line',
      data: {
          labels: this.months,
          datasets: [
            {
              label: '# Bénéfice brute en pourcentage',
              data: this.bonus,
              borderWidth: 1
            }
          ]
      },
      options: this.options
    });
  }

  _calculateBonus(i){
    return parseInt((this._calculateMonthSales(i)*this.bonusInPercentageTarget.value)/100)
  }

  _primeInPercentage(){
    this.prime = [this._calculatePrime(0)];

    for (var i = 1; i < 12; i++ ) {
      this.prime.push(this._calculatePrime(i));
    }

    new Chart(this.contentPrimeInPercentageTarget, {
      type: 'line',
      data: {
          labels: this.months,
          datasets: [
            {
              label: '# Les primes',
              data: this.prime,
              borderWidth: 1
            }
          ]
      },
      options: this.options
    });
  }

  _calculatePrime(i){
    return parseInt((this._calculateMonthSales(i)*this.primeInPercentageTarget.value)/100)
  }

  _getFinaleDistributor(){
    let finalDistributor = this.distributorsGrowth[this.distributorsGrowth.length-1];
    this.finalDistributorTarget.textContent = finalDistributor;
  }

  _getNetFinancial(){
    let netFinancial = this.salesGrowth.reduce((total, next) => {return total + next});
    this.netFinancialTarget.textContent = netFinancial
  }

  _getTotalExpenses(){
    let totalExpenses = this.expenseTarget.value
    this.totalExpensesTarget.textContent = totalExpenses;
  }

  _getTotalBonus(){
    let totalBonus = this.bonus.reduce((total, next) => {return total + next});
    this.totalBonusTarget.textContent = totalBonus;
  }

  _getTotalPrime(){
    let totalPrime = this.prime.reduce((total, next) => {return total + next});
    this.totalPrimeTarget.textContent = totalPrime;
  }

  _getTotalBenefict(){
    let totalBenefict = this.benefict.reduce((total, next) => {return total + next});
    this.totalBenefictTarget.textContent = totalBenefict;
  }
}