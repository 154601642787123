import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $('.godfather_select').on('select2:select', function (e) {
      var distributor_id = e.params.data.id;
      $.ajax({
        type: 'GET',
        url: "/distributors/suggest_sponsors/"+distributor_id
      })
      .done(function(data) {
        console.log("Success");
      })
      .fail(function(data) {
        console.log("Error");
      });
    });
  }
}
